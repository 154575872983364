import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {
	apiHost,
	getKey,
	isAccountant,
	isController,
	isMainController,
	isMarketing,
	logout,
	pageNotFound
} from "./Utils";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uz from 'date-fns/locale/uz';
import {NavLink} from "react-router-dom";
import Modal from "react-responsive-modal";
import authHeader, {blobAuthHeader} from "./services/auth-header";
import TableHeader from "./components/TableHeader";
import Autocomplete from 'react-autocomplete';

registerLocale('uz', uz);
setDefaultLocale('uz');
const headers = [{
			id: 1,
			name: '№',
			enabled: true,
			code: 'id'
		}, {
			id: 2,
			name: 'Tinglovchining F.I.Sh',
			enabled: true,
			code: 'initial'
		}, {
			id: 3,
			name: 'Pasport raqami',
			enabled: true,
			code: 'passportNumber'
		}, {
			id: 4,
			name: 'Sertifikat raqami',
			enabled: true,
			code: 'certificateNumber'
		}, {
			id: 5,
			name: 'Qayd raqami',
			enabled: true,
			code: 'registerNumber'
		}];

export default class GroupView extends Component {

	constructor() {
		super();
		let courseId = localStorage.getItem('courseId');
		let headerList = headers.map(header=> {
			return {cssClassName: "align-middle", text: header.name}
		});

		this.state = {
			course: {
				name: '',
				description: '',
				startDate: new Date(),
				endDate: new Date(),
				examDate: new Date(),
                listenerCount: '',
                tendency: {
					id: null,
					name: '',
				    code: 'paid'
                },
				studyType: {
					id: 1
				},
				status: {
					id: 1
				}
			},
			rows: [],
			peoples: [],
			peopleList: [],
			tendencies: [],
			tableHeaders: headerList,
			addListenerModal: false,
			tableSettingModal: false,
			amountModalOpen: false,
			importModalOpen: false,
			courseId: courseId,
			listenerIds: '',
            listenerId: null,
			tendency: ''
		};
		this.setValue = this.setValue.bind(this);
	}

	openModal(modalName) {
		if (modalName === 'addListenerModal') {
			axios.get(apiHost + '/api/course/peoples', authHeader())
				.then(result => {
					if (result.status === 200) {
						let rows = [], list = [];
						if (result.data !== undefined && result.data !== null) {
							rows = result.data.map((r, index) => {
								return <tr>
									<td><input id={'ch_' + r.id} type="checkbox"/></td>
									<td>{index + 1}</td>
									<td>{r.name}</td>
									<td>{r.code}</td>
								</tr>
							});
							list = result.data;
						}
						this.setState({
							peoples: rows,
							peopleList: list
						})
					}
				}).catch(error => {
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			});
		}
		this.setState({
			[modalName]: true
		})
	}

	closeModal(modalName) {
		this.setState({
			[modalName]: false
		})
	}

	courseStart() {
		axios.post(apiHost + '/api/course/start?id=' + this.state.courseId, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else if (result.status === 500) {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	courseFinish() {
		axios.post(apiHost + '/api/course/finish?id=' + this.state.courseId, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else if (result.status === 500) {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	generateAllCertificates() {
		axios.post(apiHost + '/api/course/allCertificates/generate?groupId=' + this.state.courseId, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else if (result.status === 500) {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    edit(listenerId) {
        localStorage.setItem('listenerId', listenerId);
        window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
    }

	componentDidMount() {
        let user = localStorage.getItem('user');
        if (user !== undefined && user !== null) {
            user = JSON.parse(user);
        } else {
			logout();
		}
		axios.get(apiHost + '/api/course/item?id=' + this.state.courseId, authHeader()).then(result=>{
            if (result.status === 200) {
				if (result.data.tendency.code === 'paid') {
					headers.push({
						id: 6,
						name: 'Shartnoma raqami',
						enabled: true,
						code: 'agreementNumber'
					});
					headers.push({
						id: 7,
						name: 'To‘langan summa',
						enabled: true,
						code: 'payedAmount'
					});
				}
				headers.push({
					id: 8,
					name: 'Amal',
					enabled: true,
					code: 'action'
				});
                axios.get(apiHost + '/api/agreement/topicTendencies?id=' +  result.data.topicId, authHeader()).then(result2 => {
                    if (result2.status === 200) {
                        if (result2.data !== undefined && result2.data !== null) {
                            this.setState({
                                tendencies: result2.data
                            });
                        }
                    }
                });
                let rows = [];
                if (result.data.listeners !== undefined && result.data.listeners !== null) {
                    rows = this.renderTableRows(result.data.listeners, result.data.tendency);
				}

				this.setState({
					tableHeaders: headers.map(header=> {
						return {cssClassName: "align-middle", text: header.name}
					}),
					course: result.data,
					tendency: result.data.tendency.name,
                    rows: rows
                })
            } else {
				swal(result.data.message, '', 'error');
			}
        }).catch(error=>{
			console.log(error);
			/*if (error !== undefined && error !== null && error.response !== undefined && error.response !== null && error.response.status === 401) {
				logout();
			}*/
		})
	}

	addListener() {
		let ids = '';
		for (let i=0; i<this.state.peoples.length; i++) {
			let c = this.state.peoples[i].props.children;
			if(document.getElementById(c[0].props.children.props.id).checked) {
				ids = ids + c[0].props.children.props.id.replace('ch_', '') + ',';
			}
		}

		axios.post(apiHost + '/api/course/addListener?id=' + this.state.courseId + '&listenerIds=' + ids, null, authHeader())
			.then(result=>{
				if (result.status === 200) {
					let rows = this.state.rows;
					let newRows = result.data.map(r=>{
						return <tr><td>{r.id}</td><td>{r.name}</td><td>{r.code}</td></tr>
					});
					this.setState({
						rows: rows.concat(newRows),
						modalOpen: false
					});
					swal("Tanlanganlar guruhga qo`shildi", '', 'success');
				} else {
					swal("Tanlanganlarn guruhga qo`shishda xatolik", '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	importListener() {
		let files = document.getElementById('fileUpload').files;
		if (files === null || files.length === 0) {
			swal('Import faylini tanlang', '', 'error');
			return;
		}

		const user = JSON.parse(localStorage.getItem('user'));
		let token = '';
		if (user && user.accessToken) {
			token = 'Bearer ' + user.accessToken;
		}

		let file = files[0];
		let data = new FormData();
		data.append("id", Number(this.state.courseId));
		data.append("file", file);
		axios.post(apiHost + '/api/course/importListeners', data, {
			headers: {
				'Content-type': 'multipart/form-data',
				'Authorization': token,
				'Access-Control-Allow-Headers': '*',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE'
			}
		})
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error => {
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	setDate(id, selectedDate) {
		this.setState({
			course: {
				...this.state.course,
				[id]: selectedDate
			}
		})
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		this.setState({
			course: {
				...this.state.course,
				[name]: value
			}
		})
	}

	setSelectValue(e) {
		let id = e.target.id;
		let value = e.target.value;
		this.setState({
			course: {
				...this.state.course,
				[id]: {
					id: value
				}
			}
		})
	}

	updateGroup() {
		if (this.state.course.tendency === null || this.state.course.tendency.id === null) {
			swal('Yo`nalishni tanlang', '', 'error');
			return;
		}
		let data = {};
		data.id = Number(this.state.courseId);
		data.name = this.state.course.name;
		data.description = this.state.course.description;
		data.listenerCount = this.state.course.listenerCount;
		data.tendency = this.state.course.tendency;
		data.startDate = this.state.course.startDate;
		data.endDate = this.state.course.endDate;
		data.examDate = this.state.course.examDate;
		data.studyType = this.state.course.studyType;
		axios.post(apiHost + '/api/course/update', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    inputAmount(listenerId) {
        this.setState({
            listenerId: listenerId,
            amountModalOpen: true
        });
    }

    onCloseAmountModal() {
        this.setState({
            amountModalOpen: false
        })
    }

    enterPayedAmount() {
		let payedAmount = document.getElementById('payedAmount').value;
		if (payedAmount === null || payedAmount === '' || Number(payedAmount) === 0) {
			swal('To‘langan summani kiriting', '', 'error');
			return;
		}
        axios.post(apiHost + '/api/listener/enterPayedAmount?listenerId=' + this.state.listenerId + '&amount=' + payedAmount, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    swal(result.data.message, "", "success");
                    this.onCloseAmountModal();
                    setTimeout(function () {
                        window.location.reload();
                    }, 2000);
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
    }

	exportListenerList() {
		let data = {
			"groupId": this.state.courseId,
			"usePaging": false,
			"headers": headers.filter(h=>h.enabled === true).map(header=>{
				return header.code;
			})
		}

		axios.post(apiHost + "/api/reports/listener/list", data, blobAuthHeader())
			.then(result => {
				if (result.status === 200) {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([result.data]));
					link.setAttribute('download', 'Hisobot.xlsx');

					document.body.appendChild(link);

					link.click();
					link.parentNode.removeChild(link);
				}
			})
	}

	renderTable() {
		let tableHeaders = [];
		headers.map(header => {
			let checked = document.getElementById('header' + header.id).checked;
			if (checked) {
				header.enabled = true;
				tableHeaders.push({cssClassName: "align-middle", text: header.name});
				return header.code;
			} else {
				header.enabled = false;
			}
		});
		let rows = this.renderTableRows(this.state.course.listeners, this.state.course.tendency);
		this.setState({
			tableHeaders: tableHeaders,
			rows: rows
		});
		this.closeModal('tableSettingModal');
	}

	renderTableRows(listeners, tendency) {
		return listeners.map((r, index) => {
			return <tr key = {getKey()}>
				{headers[0].enabled && <td>{index + 1}</td>}
				{headers[1].enabled && <td>{r.lastName + ' ' + r.firstName + ' ' + r.middleName}</td>}
				{headers[2].enabled && <td>{r.passportNumber !== undefined && r.passportNumber !== null ? r.passportNumber : ''}</td>}
				{headers[3].enabled && <td>{r.certificateNumber !== undefined && r.certificateNumber !== null ? r.certificateNumber : ''}</td>}
				{(headers[4].enabled) &&
					<td>{r.registerNumber}</td>
				}
				{(tendency.code === 'paid' && headers[5].enabled) &&
					<td>{r.agreement.name}</td>
				}
				{(tendency.code === 'paid' && headers[6].enabled) &&
					<td>{r.payedAmount}</td>
				}
				{(tendency.code === 'paid' && headers[7].enabled || tendency.code === 'free' && headers[5].enabled) &&
					<td>
						{isAccountant() ?
							<button className="btn btn-info" title="Summani kiritish"
									onClick={this.inputAmount.bind(this, r.id)}><i className="fa fa-edit"/>
							</button>
							:
							<button className="btn btn-success" title="Tahrirlash"
									onClick={this.edit.bind(this, r.id)}><i className="fa fa-edit"/>
							</button>
						}
					</td>
				}
			</tr>
		});
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="container-fluid card card-body mt-1 shadow-sm mb-5">
					<div className="form-row">
						<div className="col-md-6">
							<label htmlFor="groupName">Guruh nomi</label>
							<input className="form-control" id="name" defaultValue={this.state.course.name} onChange={this.setValue}/>
						</div>
						<div className="col-md-4">
							<label htmlFor="description">Izoh</label>
							<input className="form-control" id="description" defaultValue={this.state.course.description} onChange={this.setValue}/>
						</div>
						<div className="col-md-2">
							<label htmlFor="listenerCount">Tinglovchi soni</label>
							<input className="form-control" id="listenerCount" defaultValue={this.state.course.listenerCount} onChange={this.setValue}/>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-9">
							<label htmlFor="tendency">Yo`nalishi</label>
							<Autocomplete
								id="tendency"
								wrapperStyle={{width: "100%"}}
								inputProps={{
									className: 'form-control'
								}}
								value={this.state.tendency}
								items={this.state.tendencies}
								getItemValue={item => item.name}
								shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
								renderItem={(item, highlighted) =>
									<div key={item.id} style={{ backgroundColor: highlighted ? '#eee' : 'transparent'}}>
										{item.name}
									</div>
								}
								renderMenu={item => (
									<div className="dropdown">
										{item}
									</div>
								)}

								onChange={(event, val) => {
									this.setState({
										tendency: val
									})
								}}
								onSelect={(event, val) => {
									this.setState({
										tendency: val.name,
										course: {
											...this.state.course,
											tendency: val
										}
									})
								}}
							/>
						</div>
						<div className="col-md-3">
							<label htmlFor="studyType">Ta'lim shakli</label>
							<select className="form-control" id="studyType" value={this.state.course.studyType.id} onChange={this.setSelectValue.bind(this)}>
								<option key = {getKey()} value={1}>An'anaviy</option>
								<option key = {getKey()} value={2}>Onlayn</option>
								<option key = {getKey()} value={3}>Offlayn</option>
								<option key = {getKey()} value={4}>Sayyor</option>
							</select>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-4">
							<label htmlFor="startDate">Boshlangan vaqti</label>
							<DatePicker className="form-control" id="startDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.startDate !== undefined && this.state.course.startDate !== null ? this.state.course.startDate : new Date()} onChange={this.setDate.bind(this, 'startDate')} showMonthYearDropdown/>
						</div>
						<div className="col-md-3">
							<label htmlFor="endDate">Tugagan vaqti</label>
							<DatePicker className="form-control" id="endDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.endDate !== undefined && this.state.course.endDate !== null ? this.state.course.endDate : new Date()} onChange={this.setDate.bind(this, 'endDate')} showMonthYearDropdown/>
						</div>
						<div className="col-md-3">
							<label htmlFor="examDate">Imtihon vaqti</label>
							<DatePicker className="form-control" id="examDate" locale="uz" dateFormat="dd.MM.yyyy" selected={this.state.course.examDate !== undefined && this.state.course.examDate !== null ? this.state.course.examDate : new Date()} onChange={this.setDate.bind(this, 'examDate')} showMonthYearDropdown/>
						</div>
						<div className="col-md-2">
							<button className="btn btn-success" onClick={this.updateGroup.bind(this)}>Saqlash</button>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-12 text-center">
							{isMarketing() &&
								<>
									<button className="btn btn-info mr-1" onClick={this.openModal.bind(this, 'addListenerModal')}>Tinglovchi
										qo`shish
									</button>
									<button className="btn btn-info mr-1" onClick={this.openModal.bind(this, 'importModalOpen')}
											title="Import">
										<i className="fa fa-file-excel-o"/>
									</button>
								</>
							}
							{(isController() || isMainController()) &&
								<>
									{this.state.course.status.id < 2 &&
										<button className="btn btn-info mr-1" onClick={this.courseStart.bind(this)}>O`quv
											jarayonini boshlash
										</button>
									}
									{this.state.course.status.id === 2 &&
										<button className="btn btn-info mr-1" onClick={this.courseFinish.bind(this)}>O`quv
											jarayonini yakunlash
										</button>
									}
									{isMainController() && this.state.course.status.id === 5 &&
										<button className="btn btn-info mr-1" onClick={this.generateAllCertificates.bind(this)}>Guruhga sertifikat berish</button>
									}
								</>
							}
							<button className="btn btn-info float-right" title="Tinglovchilar ro`yxatini Excelga eksport qilish"
									onClick={this.exportListenerList.bind(this)}>
								<i className="fa fa-file-excel-o"/>
							</button>
							<button className="btn btn-info float-right mx-1" title="Jadval sozlamalari"
									onClick={this.openModal.bind(this, 'tableSettingModal')}>
								<i className="fa fa-cog"/>
							</button>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="col-md-12">
							<table className="table table-striped table-bordered shadow">
								<thead>
								<TableHeader items = {this.state.tableHeaders}/>
								</thead>
								<tbody>
								{this.state.rows}
								</tbody>
							</table>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12 text-center mt-3">
							<NavLink className="btn btn-success" to="/groups">Ro`yxatga qaytish</NavLink>
						</div>
					</div>
				</div>
				<Modal open={this.state.tableSettingModal} onClose={this.closeModal.bind(this, 'tableSettingModal')} center>
					<div style={{margin: "30px 0 0 0 "}}>
						<div className="form-row mb-3">
							<div className="col-md-12">
								<h5>Jadval ustunlarini tanlang</h5>
							</div>
						</div>
						<div className="form-row mb-3">
							<div className="col-md-12 d-flex">
								{headers.map(header => {
									return <div key={getKey()} className="form-check mx-2">
										<input className="form-check-input" type="checkbox" defaultChecked={header.enabled}
											   id={"header" + header.id}/>
										<label className="form-check-label"
											   htmlFor={"header" + header.id}>{header.name}</label>
									</div>;
								})}
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-12 text-center">
								<button className="btn btn-info" onClick={this.renderTable.bind(this)}>OK</button>
							</div>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this, 'modalOpen')} center>
					<div style={{margin: "30px 0 0 0 "}}>
						<table className="table table-striped table-bordered shadow">
							<thead>
							<tr>
								<th scope="col" className="align-middle">&nbsp;</th>
								<th scope="col" className="align-middle">ID</th>
								<th scope="col" className="align-middle">Tinglovchining F.I.Sh</th>
								<th scope="col" className="align-middle">Yo`nalishi</th>
							</tr>
							</thead>
							<tbody>
							{this.state.peoples}
							</tbody>
						</table>
					</div>
					<div className="form-row">
						<div className="col-md-12">
							<button className="btn btn-info" onClick={this.addListener.bind(this)}>Saqlash</button>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.importModalOpen} onClose={this.closeModal.bind(this, 'importModalOpen')} center>
					<div style={{margin: "30px 0 0 0 "}}>
						<div className="form-row">
							<div className="col-md-12">
								<a href="/files/listeners.xlsx">Shablon fayl</a>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-8">
								<input type="file" id="fileUpload"/>
							</div>
							<div className="col-md-4">
								<button className="btn btn-info" onClick={this.importListener.bind(this)}>Import qilish</button>
							</div>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.amountModalOpen} onClose={this.onCloseAmountModal.bind(this)} center>
					<div style={{padding: "20px", minWidth: "200px"}}>
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="tendency">To`lov miqdori</label>
								<input type="number" className="form-control" id="payedAmount" onChange={this.setValue}/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12 text-center">
								<button className="btn btn-success" onClick={this.enterPayedAmount.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}